@import 'common';
@import url('https://fonts.googleapis.com/css?family=Google+Sans:500&lang=en&display=swap');

html, body {
	background: $color-body-background;
}

body {
	padding: 0;

	&::after {
		display: none;
	}
}

.auth-wrapper {
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
}

.auth {
	position: relative;

	width: 400px;
	padding: 30px;
	margin: 0 auto;
}

	.auth-logo {
		$logo-size: 48px;

		position: absolute;
		top: -$logo-size - 15px;
		left: 0;

		display: block;
		float: left;
		width: 100%;
		height: $logo-size;

		font-size: $logo-size;
		line-height: $logo-size;
		color: $color-primary-purple;
		text-align: center;
	}

	.auth-error, .auth-success {
		display: block;
		padding: 12px 12px 10px;
		margin-bottom: 30px;
		color: $color-white;

		background: $color-secondary;
		border-radius: 5px;
	}

		.auth-error-title {
			margin: 0;
			color: inherit;
		}

		.auth-success {
			background: $color-primary;
		}

	.auth-field, .auth-button {
		display: block;
		height: 32px;
		padding: 0 14px;
		line-height: 32px;

		border-radius: $border-radius-size;
	}

	.auth-field {
		width: 100%;

		background: $color-input-muted;
		border: 1px solid $color-input-muted;
		transition: background-color $transition-timing, border-color $transition-timing;

		&:focus {
			background-color: $color-white;
			border-color: $color-border;
		}

		& + & {
			margin-top: 10px;
		}
	}

	.auth-buttons {
		display: block;
		margin: 20px auto 0;

		text-align: center;
	}

	.auth-qr {
		display: block;
		width: 200px;
		height: 200px;
		margin: 0 auto 30px;

		svg {
			width: 100%;
			height: 100%;
		}
	}

	.auth-spacer {
		height: 15px;
	}

	.auth-button {
		display: inline-block;
		width: 100%;
		margin: 0 auto;

		color: $color-white;

		background: $color-primary;
		border: 0;
		box-shadow: 0 1px 2px 0 rgba($color-black, 0.10);

		&:hover {
			color: $color-white;
		}
	}

		.auth-button-secondary {
			background: $color-muted;
		}

	.auth-message {
		margin: 0 0 30px;

		font-size: $body-font-size;
		font-weight: $font-weight-normal;
		color: $color-muted;
	}

	.auth-separator {
		width: 100%;
		height: 1px;
		margin: 30px auto;

		background: $color-border;
		border: 0;
		text-align: center;
	}

	.auth-separator-text {
		text-transform: uppercase;
		padding: 10px;
		color: $color-muted;
		background-color: $color-white;
		margin-top: -20px;
		margin-left: -20px;
		position: absolute;
	}

	.auth-list {
		display: block;
		margin-top: -15px;
	}

		.auth-list-item {
			display: block;

			& + & {
				margin-top: 3px;
			}
		}

		.auth-list-item-label {
			display: block;
			height: 22px;

			font-size: 15px;
			line-height: 24px;
			color: $color-muted;
		}

.auth-additional-information {
	// position: absolute;
	// bottom: -30px;
	left: 0;
	width: 100%;
	text-align: center;
	margin-top: 15px;
}

	.auth-additional-link {
		font-size: 12px;
		color: $text-muted;
	}

.auth-sso {
	// position: absolute;
	// bottom: -80px;
}

.form-toggle {
	margin-top: -3px;
	margin-right: 5px;
}

.ms-signin-button {
	display: flex;
	// align-items: center;
	// justify-content: center;
	padding: 5px;
	border: 1px solid #ccc;
	border-radius: 4px;
	text-decoration: none;
	color: #000;
	background-color: #fff;
	width: 340px;
	margin-top: 10px;

	&:hover {
		border-color: #d2e3fc;
		background-color: rgba(66,133,244,.04);
	}
}

.ms-signin-button img {
	width: 20px;
	height: 20px;
	margin-left: 3px;
	margin-right: 8px;
}

.ms-signin-text {
	color: rgb(60, 64, 67);
	width: 100%;
	text-align: center;
	font-family: "Google Sans",arial,sans-serif;
	font-weight: 500;
	font-size: 14px;
	text-overflow: ellipsis;
	overflow: hidden;
	vertical-align: top;
}
